.return-order-list {
    .page-title h1 {
        text-transform: uppercase;
        text-align: center;
    }
    .data-table {
        th {
            background: transparent;
        }
        .price {
            font-family: $f-title;
        }
        .nobr {
            font-family: $f-title;
        }
        .table-footer {
            span {
                font-family: $f-title;
            }
        }
    }
    .btn-primary {
        min-width: unset;
    }
}
.return-order-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    max-width: 960px;
    margin: 0 auto;
    .page-description {
        margin-bottom: 10px;
    }
}
#returnForm {
    width: 100%;
    label[for=reason] {
        display: block;
    }
    #reason {
        display: block;
        margin: 0 auto;
    }
    .form-list {
        width: 100%;
        max-width: none;
    }
    .return-info {
        margin: 40px auto 50px;
        width: 90%;
        max-width: 400px;
    }
    .order-number {
        text-transform: uppercase;
        text-align: left;
    }
    .product-info-container {
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        border-bottom: 1px solid black;
        .product-check {
            width: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .product-image {
            width: 15%;
            border: none;
        }
        .product-info {
            width: 40%;
            display: flex;
            flex-direction: column;
            text-align: left;
            padding-left: 20px;
        }
        .product-option {
            font-size: 10px;
        }
        .product-disclaimer {
            width: 40%;
        }
    }
    button {
        width: auto;
        float: right;
    }
}
@media screen and(max-width: $bp-medium) {
    #returnForm {
        .product-info-container {
            .product-image {
                width: 40%;
            }
            .product-info {
                width: 50%;
            }
            .product-disclaimer {
                width: 100%;
            }
        }
        button {
            width: auto;
            float: none;
            margin: 20px auto 0;
        }
    }
}
.return-order-submitted {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    max-width: 960px;
    margin: 0 auto;
    .page-description {
        margin-bottom: 10px;
    }
}
.return-order-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    max-width: 960px;
    margin: 0 auto;
    .page-description {
        margin-bottom: 10px;
        font-family: $f-title;
        letter-spacing: 2px;
        line-height: 25px;
        font-weight: $f-weight-regular;
    }
    .page-title {
        margin-top: 85px;
        h1 {
            text-transform: uppercase;
        }
    }
}
.return-order-search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    label {
        width: 100%;
        text-align: left;
        margin-top: 30px;
    }
    .required {
        opacity: 0;
    }
    .button {
        margin-top: 50px;
        width: auto;
        background-color: $black;
        color: $white;
        &:hover {
            background-color: $white;
            color: $black;
        }
    }
}
.box-returns .box-inner {
    text-align: center;
    .btn-primary {
        width: auto;
        margin: 20px auto;
        float: none;
    }
}
