$padding-top-desktop: 20px;
$padding-bottom-desktop: 50px;

.cosmo-customizer-index-index {
  //.sticky-wrapper {
  //  text-align: center;
  //  width: 66.66667%;
  //}
  .images-container {
    text-align: center;
    width: 66.66667%;
    #threesixty {
      margin: auto;
      //max-width: 100%;
      max-width: 750px;
      max-height: 750px;
    }
  }
  .initials-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .front-img-detail {
      max-width: 500px;
      max-height: 500px;
    }
    .back-img-detail {
      position: relative;
      img {
        position: absolute;
        top: 0;
        &:first-child {
          position: unset;
        }
      }
      &.visible-detail {
        width: 500px;
        height: 500px;
      }
    }
  }
  .sticky-wrapper {
    //width: 33.33333%;
    //float: right;
  }
  .product-info-container {
    width: 33.33333%;
    padding: 0 15px;
    .customizer-block {
      padding-top: $padding-top-desktop;
      padding-bottom: $padding-bottom-desktop;
      border-bottom: 1px solid $black;
      .actions-mobile {
        display: none;
      }
    }
    .top-block {
      padding-bottom: 80px;
      border-bottom: 1px solid $black;
      .name {
        font-size: $f-size-xxl;
        margin-bottom: 20px;
      }
      .price {
        display: flex;
        flex-direction: row;
        float: left;
        flex-wrap: wrap;
        width: 25%;
        position: relative;
        .symbol {
          margin-right: 5px;
        }
        .value {
          padding-right: 5px;
        }
        .info-iva {
          width: 300%;
          font-size: $f-size-xxs;
          position: absolute;
          bottom: -51px;
        }
      }
      .delivery_time_date {
        float: right;
        font-family: $f-title;
        text-transform: uppercase;
        font-size: $f-size-xs;
        letter-spacing: 2px;
      }
    }
    .title {
      font-size: $f-size-xs;
      font-family: $f-title;
      text-transform: uppercase;
      margin-bottom: 15px;
      letter-spacing: 2px;
    }
    .subtitle {
      font-size: $f-size-xs;
      font-family: $f-title;
      margin-bottom: 35px;
      letter-spacing: 2px;
    }
    .table-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      .id {
        @include block-uppercase-subtitle;
        min-width: 85px;
        text-transform: uppercase;
      }
      .values {
        width: 85%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .value {
          margin-right: 15px;
          label {
            img {
              max-width: 45px;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    input[type=radio] {
      display: none;
    }
    input[type=radio]:checked + label {
      border: 2px solid $black;
      pointer-events: auto;
    }
    input[type=radio]:disabled + label {
      opacity: 0.1;
      border: 2px solid transparent;
      cursor: initial;
      pointer-events: none;
    }
    input[type=radio] + label {
      border: 2px solid transparent;
      cursor: pointer;
      pointer-events: auto;
    }
    .initials-block {
      padding-bottom: 0px;
      position: relative;
      .letters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .letter {
          position: relative;
          padding-bottom: $padding-bottom-desktop;
          &:first-child {
            //max-width: 40%;
          }
          label {
            @include block-uppercase-subtitle;
            width: 100%;
          }
          input {
            max-width: 50px;
            border: 1px solid $black;
            font-size: $f-size-xxxl;
            font-style: italic;
            font-family: $f-paragraph;
            text-align: center;
          }
          .validation-advice {
            position: absolute;
            padding-right: 10px;
          }
          .suggestion {
            color: $c-danger;
          }
        }
      }
      .suggestion-initials {
        display: none;
      }
      .validation-letter-advice {
        display: none;
        //position: absolute;
        bottom: 0;
        font-family: $f-title;
      }
      .initials-details-container {
        display: none;
      }
    }
    .size-block {
      padding-top: $padding-top-desktop;
      border: none;
      padding-bottom: 0;
      .title {
        > a {
          float: right;
        }
      }
      .values {
        label {
          @include block-uppercase-subtitle;
          padding: 5px;
        }
        .size-separator {
          display: inline-block;
          &:after {
            content: '-';
            margin: 0 5px;
          }
          &:last-child {
            display: none;
          }
        }
      }
      .popup-overlay {
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1042;
        overflow: hidden;
        position: fixed;
        background: $white;
        opacity: .8;
      }
      .popup-size-guide {
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1043;
        position: fixed;
        outline: none !important;
        text-align: center;
        &:before {
          content: '';
          display: inline-block;
          //height: 100%;
          vertical-align: middle;
        }
        .closepopup {
          background-image: url("../images/close-icon.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 15px;
          padding-top: 30px;
          padding-right: 30px;
          width: 15px;
          position: absolute;
          top: 0;
          right: 0;
        }
        #size-guide {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin: 0 auto;
          text-align: left;
          z-index: 1045;
          background-color: $white;
          padding: 20px;
          border: 1px solid $black;
          top: 50%;
          transform: translate(0, -50%);
          .subtitle {
            @include block-uppercase-subtitle;
            @include paragraph-block-title;
          }
        }
      }
      .size-guide-title {
        padding: 10px 0;
        padding: 10px 0;
        @include block-uppercase-subtitle;
        @include paragraph-block-title;
        text-transform: uppercase;
        text-align: center;
      }
      .sizeguide {
        margin-bottom: 30px;
        table {
          font-family: $f-title;
          font-size: $f-size-xxs;
        }
        tr {
          display: flex;
          flex-direction: row;
          .first-column {
            position: absolute;
            width: 100px;
            color: #000;
            //border-right: 1px solid #000;
            padding: 0 10px;
            padding-right: 20px;
            justify-content: left;
            flex-wrap: wrap;
            background-color: $white;
          }
          th {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            height: 45px;
            min-width: 50px;
            &:nth-child(2) {
              min-width: 110px;
            }
          }
          &:nth-child(even) {
            background-color: #f8f5f5;
          }
        }
      }
    }
    .buttons {
      .btn-cart {
        max-width: 170px;
        margin-top: $padding-bottom-desktop;
        &.not-clickable {
          pointer-events: none;
        }
      }
      .top-block {
        display: none;
      }
    }
  }
}

/** RESPONSIVE **/

@include bp(max-width, $bp-large) {
  .cosmo-customizer-index-index {
    .images-container {
      width: 100%;
      //padding-bottom: 100%;
      .slick-slider {
        .slick-arrow {
          width: 20px;
          height: 20px;
          border: 2px solid $black;
          position: absolute;
          bottom: 0px;
          z-index: 1;
          div {
            width: 5px;
            height: 5px;
            border: 1px solid black;
            margin: auto;
            border-bottom: none;
            border-right: none;
            transform: rotate(-45deg);
            margin-top: 5px;
          }
        }
        .arrow-prev {
          left: 0;
        }
        .arrow-next {
          right: 0;
          div {
            transform: rotate(135deg);
            margin-right: 7px;
          }
        }
      }
      .initials-details-container {
        display: none;
      }
    }
    .product-info-container {
      width: 100%;
      .top-block {
        //display: none;
        padding-top: 20px;
      }
      .customizer-block {
        display: none;
        padding-bottom: 0;
        border-bottom: none;
        min-height: 240px;
        position: relative;
        //.subtitle {
        //  display: none;
        //}
        .actions-mobile {
          //position: absolute;
          bottom: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          //padding-top: 50px;
          a, button {
            @include block-uppercase-subtitle;
            text-align: center;
            background: $black;
            color: $white;
            max-width: 100px;
            padding: 0;
            flex-basis: 100%;
            margin: auto;
            margin-top: 20px;
            line-height: 40px;
            &:hover {
              background: $white;
              color: $black;
            }
            &.prev {
              background: $white;
              color: $black;
              order: 2;
              &:hover {
                background: $black;
                color: $white;
              }
            }
          }
        }
        //&.primary-block {
        //  display: block;
        //}
        .options-table {
          z-index: 1;
          .table-row {
            display: block;
            &:focus {
              outline: unset;
            }
            //position: relative;
            .id {
              //position: absolute;
              //display: inline-block;
              //right: 0;
              //top: 0;
              //text-align: right;
            }
            .values {
              padding-top: 20px;
              //display: inline-block;
            }
          }
          .slick-arrow {
            width: 10px;
            height: 20px;
            position: absolute;
            bottom: 0px;
            z-index: 1;
          }
          .mat-arrow-next {
            right: 0;
            &:after {
              content: '>';
            }
          }
          .mat-arrow-prev {
            left: 0;
            &:after {
              content: '<';
            }
          }
        }
        &.initials-block {
            .suggestion-initials {
              width: 100%;
              display: none;
              //position: absolute;
              font-family: $f-title;
            }
          .letters {
            flex-wrap: nowrap;
            .letter {
              .suggestion {
                position: absolute;
                top: 60px;
              }
            }
          }
          .validation-letter-advice {
            bottom: unset;
          }
          .initials-details-container {
            display: flex;
            .front-img-detail {
              display: none;
            }
          }
        }
        &.current-step {
          display: block;
        }
      }
      .buttons {
        display: none;
        .top-block {
          display: none;
          padding-bottom: 0;
          border: none;
          .price {
            float: unset;
            padding: 10px 0;
          }
          .delivery_time_date {
            float: unset;
            @include block-uppercase-subtitle;
          }
        }
        &.current-step {
          display: block;
        }
      }
    }
  }
}

@include bp(max-width, $bp-medium) {
  .cosmo-customizer-index-index {
    .product-info-container {
      .customizer-block.initials-block {
        .initials-details-container {
          flex-wrap: wrap;
          .back-img-detail.visible-detail {
            height: 100%;
          }
        }
      }
    }
  }
}
@include bp(max-width, $bp-xsmall) {
  .cosmo-customizer-index-index {
    .product-info-container {
      .initials-block {
        .letters {
          .letter {
            input {
              max-width: 120px;
            }
          }
        }
      }
    }
  }
}