
// =============================================
// Stili generici pagine CMS
// =============================================

.cms-page-view {
  .page-title {
    display: none;
  }
  .breadcrumbs {
    //display: none;
    overflow: visible;
    margin: 0;
    margin-bottom: 40px;
  }
  h1 {
    @include block-italic-title;
    color: $black !important;
    text-transform: initial;
    font-style: normal;
  }
  h2 {
    @include block-italic-title;
    text-transform: initial;
    font-style: normal;
    font-size: $f-size;
    color: $black !important;
    margin-bottom: 0;
  }
  h4 {
    font-family: $f-paragraph;
    text-transform: uppercase;
    font-size: $f-size-xxl;
    color: $black;
    font-weight: $f-weight-regular;
  }
  .title-service-sidebar {
    @include block-italic-title;
    color: $black !important;
    text-transform: initial;
    font-style: normal;
    margin-bottom: 30px;
    line-height: 1.2;
  }
  ul.service-list {
    li {
      @include block-uppercase-subtitle;
      padding-bottom: 12px;
    }
  }
  .store-data {
    list-style: none;
    li {
      @include block-uppercase-subtitle;
      font-style: normal;
      color: $black;
      margin-left: 0;
    }
  }
  #contactForm {
    padding-left: 0;
    margin-top: 45px;
    .fieldset {
      width: fit-content;
      //width: 100%;
      .required {
        font-family: $f-title;
      }
      .check-terms {
        display: flex;
        input[type="checkbox"] {
          margin-top: 7px;
          margin-right: 10px;
        }
      }
    }
    ul {
      list-style: none;
      li {
        margin-left: 0;
        label {
          font-style: normal;
        }
      }
    }
  }
  .validation-advice {
    font-style: normal;
    font-family: $f-title;
  }
  .size-guide-title {
    padding: 10px 0;
    font-weight: bold;
  }
  .sizeguide {
    margin-bottom: 30px;
    table {
      font-family: $f-title;
      font-size: $f-size-xxs;
    }
    tr {
      display: flex;
      flex-direction: row;
      .first-column {
        position: absolute;
        width: 100px;
        color: #000;
        //border-right: 1px solid #000;
        padding: 0 10px;
        padding-right: 20px;
        justify-content: left;
        flex-wrap: wrap;
        background-color: $white;
      }
      th {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        height: 45px;
        min-width: 50px;
        &:nth-child(2) {
          min-width: 110px;
        }
      }
      &:nth-child(even) {
        background-color: #f8f5f5;
      }
    }
  }
  .pageCnt {
    max-width: 760px;
    @include block-uppercase-subtitle;
    @include paragraph-block-title;
    margin:auto;
    p {
      font-family: $f-title;
    }
    .title {
      font-family: $f-paragraph;
      font-size: $f-size;
      text-transform: uppercase;
      color: $black;
      padding: 20px 0;
      margin-bottom: 0;
    }
    h2.title {
      margin-bottom: 35px;
      padding: 0;
    }
    &.contatti {
      text-align: center;
      > img {
        max-width: 300px;
        margin: auto;
        margin-bottom: 56px;
      }
      p {
        font-size: $f-size;
        line-height: 22px;
        letter-spacing: 2px;
        margin-bottom: 60px;
      }
    }
  }
  .mapouter {
    .gmap_canvas {
      width: 100%;
      #gmap_canvas {
        width: 100%;
        min-height: 646px;
      }
    }
  }
  .iub_content, .iubenda_legal_document {
    p {
      font-family: $f-title;
      font-size: $f-size;
      font-weight: $f-weight-regular;
      letter-spacing: 2px;
      line-height: 25px;
    }
    h1 strong {
      font-weight: $f-weight-regular;
    }
    h2, h3 {
      font-family: $f-paragraph;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 2.7px;
      line-height: 18px;
      color: $black;
    }
  }
}

@include bp(max-width, $bp-large) {
  .cms-page-view {
    #contactForm {
      .fieldset {
        width: 100%;
      }
      .buttons-set {
        text-align: center;

        button.button {
          float: none;
          margin: auto;
        }
      }
      .required {
        font-size: $f-size-xxs;
      }
    }
  }
}

// =============================================
// Villa Torresi
// =============================================

.cms-block {
  .title {
    @include block-italic-title;
    color: $black !important;
    text-transform: uppercase;
    font-style: normal;
  }
  .subtitle {
    @include block-uppercase-subtitle;
    margin-top: 25px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  .paragraph {
    font-family: "Ringside", sans-serif;
    line-height: 22px;
    letter-spacing: 2px;

    .title {
      font-size: 18px;
      margin-bottom: 30px;
    }
    p {
      font-family: "Ringside", sans-serif;
      margin: 0;
    }
  }
  .cms-block__mobileimg {
    display: none;
  }
}
.cms-block {
  &.picture-text-center {
    margin: 0 0 90px 0;
    width: 100%;

    .text-center {
      max-width: 400px;
      margin: auto;
      text-align: center;
      margin-top: 80px;
      .title {
        margin-bottom: 30px;
      }
    }
  }
  &.col2-img-text {
    margin-bottom: 9%;
    .col-1 img {
      float: right;
      padding-right: 16.5%;
    }
    .col-2.paragraph {
      text-align: center;
      font-family: "Ringside", sans-serif;
      color: #0a0a0a;
      max-width: 800px;
      margin: auto;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 2px;
      font-weight: 300;

      .paragraph-container {
        max-width: 450px;
        margin: auto;

        .title {
          position: absolute;
          top: -6%;
        }
        .subtitle {
          position: absolute;
          top: -10%;
        }
      }

    }
    &.larger-img {
      .col-1 {
        width: 51%;
        padding: 0;
        float: none;

        img {
          padding-right: 0px;
        }
      }
      .col-2 {
        width: 49%;
        padding-left: 8%;
        .subtitle {
          margin-top: -114px;
          top: unset;
        }
        .title {
          margin-top: -86px;
          top: unset;
        }
      }
    }
  }
  &.video-container-boxed {
    text-align: center;
    margin-bottom: 17%;
    video {
      max-width: 100%;
      width: 1565px;
    }
    .text-left {
      width: 1565px;
      max-width: 100%;
      margin: auto;
      text-align: left;
      .subtitle {
        margin-top: 8%;
        margin-bottom: 30px;
      }
      .paragraph {
        max-width: 930px;
      }
    }
  }
  &.link-text-center {
    margin-bottom: $margin-from-footer;
    .text-center {
      max-width: 800px;
      margin: auto;
      text-align: center;
      margin-top: 80px;
      .paragraph {
        font-weight: 300;
        letter-spacing: 2px;
        line-height: 25px;
        margin: 30px 0;
      }
    }
  }

  .cms-block__mobileimg {
    width: 100%;
  }

  .cms-block__deskimg {
    width: 100%;
  }
}

@include bp(min-width, $bp-large + 1) {

  .cms-block {
    &.col2-img-text {
      position: relative;

      .col2-set {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .col-1 {
          display: flex;
          flex: 0 1 50%;
          align-items: center;
          padding: 0;
          width: unset;
          float: none;
          margin: auto;

          .cms-block__deskimg {
            margin: auto;
          }
        }

        .col-2 {
          &.paragraph {
            display: flex;
            flex: 0 1 50%;
            max-width: unset;
            float: none;
            width: unset;
            padding: unset;
            align-content: center;
            align-items: center;
            justify-content: center;
            justify-items: center;

            .paragraph-container {
              display: flex;
              flex-direction: column;
              max-width: 330px;
              margin: auto;
              align-self: center;
              justify-self: center;
              text-align: left;

              .subtitle {
                position: relative;
                top: unset;
                margin-top: unset;
              }

              .title {
                position: relative;
                margin: 0 0 30px 0;
                top: unset;
              }
            }
          }
        }
      }
    }

    &.picture-text-center {
      margin-bottom: 150px;

      .text-center {
        max-width: 800px;
      }
    }

    &.col2-img-text {
      margin-bottom: 180px;
    }

    &.video-container-boxed {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include bp(max-width, $bp-large) {
  .cms-block {
    .title {
      margin-bottom: 20px;
    }
    .cms-block__mobileimg {
      display: block;
    }
    .cms-block__deskimg {
      display: none;
    }

    &.col2-img-text {
      margin-bottom: 90px;

      .col-1 {
        width: 100%;
        img {
          float: unset;
          padding: 0;
          margin: auto;
        }
      }
      .col-2.paragraph {
        width: 100%;
        text-align: center;
        .paragraph-container {
          margin: auto;

          .subtitle {
            position: unset;
            margin-top: 50px;
            margin-bottom: 20px;
          }
          .title {
            position: unset;
          }
        }
      }
      &.larger-img {
        .col-1 {
          width: 100%;
        }
        .col-2 {
          width: 100%;
          padding: 0;
          .title {
            margin-top: 0;
          }
        }
      }
    }
    &.picture-text-center {
      margin: 0 0 90px 0;
      width: 100%;
    }
  }
}

@include bp(max-width, $bp-small) {
  .cms-block.link-text-center .text-center {
    .subtitle.btn-secondary {
      padding: 5px 40%;
    }
  }
}

// =============================================
// Tecnologia
// =============================================
.cms-tecnologia, .cms-technology {
  .page-header {
    margin-bottom: 0px;
  }
  .col-main {
    padding-left: 0;
  }
  .articles-container {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .article {
      flex-basis: 49%;
      margin-top: 50px;
      .title {
        font-family: $f-title;
        font-size: $f-size-xs;
        margin-top: 30px;
        text-transform: uppercase;
      }
      .subtitle {
        font-family: $f-paragraph;
        font-size: $f-size;
        margin: 15px 0;
        text-transform: uppercase;
      }
      .content {
        display: none;
        font-family: $f-title;
        font-size: $f-size-xs;
      }
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    margin-left: -$trim-desktop;
    padding-right: 0;
    .cms-content-block {
      position: relative;
      background-color: $black;
      color: $white;
      border-bottom: 1px solid $white;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      .content {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0%, -50%);
        //height: 100%;
        width: 100%;
        padding-left: 50px;
        .title {
          font-style: $f-size-xxl;
          margin-bottom: 10px;
        }
        .subtitle {
          @include block-uppercase-subtitle;
          color: $white;
          margin-bottom: 20px;
        }
        .paragraph {
          margin-bottom: 30px;
          max-width: 300px;
        }
        .button {
          color: $white;
          border-color: $white;
        }
      }
    }
  }
}
.article-content {
  max-width: 800px;
  margin: 0 auto;
  .title {
    font-family: $f-title;
    font-size: $f-size-xs;
    margin-top: 30px;
    text-transform: uppercase;
  }
  .subtitle {
    font-family: $f-paragraph;
    font-size: $f-size;
    margin: 15px 0;
    text-transform: uppercase;
  }
  .content {
    font-family: $f-title;
    font-size: $f-size;
  }
}

.mobile-cms-content {
  display: none;
  .close-tab {
    @include block-uppercase-subtitle;
    text-decoration: underline;
    margin-top: 40px;
  }
}

#cms-page-content, .mobile-cms-content {
  //padding-top: 80px;
  video {
    max-width: 100%;
  }
  #slider-img, #slider-img-mobile {
    margin: auto;
    display: flex;
    align-items: center;
    .slick-track {
      img {
        max-width: 515px;
        margin: auto;
        width: 100%;
      }
    }
    button {
      height: fit-content;
      position: absolute;
      top: 50%;
      z-index: 1;
      &.slick-next {
        right: 0%;
      }
    }
  }
  .cms-block {
    .text-left {
      margin-top: 70px;
      .title {
        margin-bottom: 30px;
      }
      .paragraph p {
        max-width: 400px;
        @include block-uppercase-subtitle;
      }
    }
  }
}

@include bp(max-width, $bp-large) {
  .cms-tecnologia, .cms-technology {
    .col-left.sidebar {
      @include page-full-width;
      transition: width 1s, height 1s;
      will-change: width;
    }
  }
  .mobile-cms-content {
    padding: 20px $trim-small;
  }
}
@include bp(max-width, $bp-medium) {
  .cms-tecnologia, .cms-technology {
    .articles-container .article {
      flex-basis: 100%;
    }
  }
}
@include bp(max-width, $bp-small) {
  .sidebar {
    .cms-content-block {
      .content {
        padding: 0 $trim-small;
      }
    }
  }
  #slider-img-mobile {
    .slick-arrow {
      width: 20px;
      height: 20px;
      border: 2px solid black;
      position: absolute;
      bottom: 0px;
      z-index: 1;
      &.arrow-next {
        right: 0;
        div {
          transform: rotate(135deg);
          margin-right: 7px;
        }
      }
      div {
        width: 5px;
        height: 5px;
        border: 1px solid black;
        margin: auto;
        border-bottom: none;
        border-right: none;
        transform: rotate(-45deg);
        margin-top: 5px;
      }
    }
  }
}
// =============================================
// Newsletter
// =============================================
.cms-newsletter {
  .breadcrumbs {
    display: none;
  }
  .std {
    @include page-full-width;
    display: flex;
    flex-direction: row;
    .img-container {
      flex-basis: 38%;
      img {
        float: left;
        max-width: 720px;
        width: 100%;
      }
    }
    .text-container {
      flex-basis: 62%;
      > div {
        width: 50%;
        margin: 0 auto;
        text-align: center;
        .title {
          font-family: $f-paragraph;
          font-size: $f-size;
          margin: 15px 0;
          text-transform: uppercase;
        }
        .subtitle {
          font-family: $f-title;
          font-size: $f-size-xs;
          line-height: 25px;
          margin-bottom: 50px;
          @include paragraph-block-title;
        }
        .block-subscribe {
          text-align: left;
          max-width: 292px;
          margin: 0 auto;
          > label {
            display: block;
            margin-bottom: 5px;
          }
          .validation-advice {
            position: absolute;
          }
          .check-terms {
            margin-top: 40px;
          }
          .actions {
            max-width: 200px;
            margin: 0 auto;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
@include bp(max-width, $bp-large) {
  .cms-newsletter {
    .std {
      .img-container {
        flex-basis: 100%;
        img {
          max-width: unset;
        }
      }
      .text-container {
        flex-basis: 100%;
      }
    }
  }
}
@include bp(max-width, $bp-medium) {
  .cms-newsletter {
    .std {
      flex-wrap: wrap;
      .text-container {
        padding: 0 15px;
        width: 100%;
        > div {
          width: 100%;
          .title {
            margin-top: 40px;
            font-size: $f-size-xxl;
          }
          .content {
            input[type=email] {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
// =============================================
// Assistenza
// =============================================

.cms-assistance {
  .sidebar {
    .service-list {
      li {
        @include block-uppercase-subtitle();
        text-transform: uppercase;
        &:first-child {
          margin-bottom: 35px;
          font-family: "Hoefler Text", serif;
          font-size: 14px;
          letter-spacing: 3.6px;
          text-transform: uppercase;
          line-height: 24px;
          text-align: left;
          padding: 0;
        }
      }
    }
  }
  .std {
    border-left: 1px solid $black;
    padding-left: 45px;
    .paragraph {
      display: none;
      @include block-uppercase-subtitle();
      font-size: $f-size;
      @include paragraph-block-title;

      &:first-child {
        display: block;
      }

      .title {
        font-family: $f-paragraph;
        font-size: $f-size;
        text-transform: uppercase;
        color: $black;
        //padding: 20px 0;
        padding-bottom: 25px;
        padding-top: 45px;
        margin-bottom: 0;
      }

      .store-data {
        margin-bottom: 30px;
      }

      .store-data li {
        font-family: "Ringside", sans-serif;
        color: #0a0a0a;
        max-width: 800px;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 2px;
        font-weight: 300;
        > a {
          padding-left: 20px;
          text-transform: initial;
        }
      }

      .assistance-available {
        text-align: left;
        margin-bottom: 30px;

      }

      .store-data li {
        text-transform: uppercase;
      }

      h2.title {
        margin-bottom: 35px;
        font-family: "Hoefler Text", serif;
        font-size: 14px;
        letter-spacing: 3.6px;
        text-transform: uppercase;
        line-height: 24px;
        text-align: left;
        padding: 0;
      }

      .title-content {
        display: none;
      }
    }
  }
}

@include bp(max-width, $bp-large) {
  .cms-assistance {
    .sidebar {
      display: none;
    }
    .col2-left-layout .col-main {
      float: unset;
      width: 100%;
    }
    .std {
      padding-left: 0;
      border: none;
      .paragraph:first-child {
        display: block;
        margin: 70px auto 0;
        max-width: 400px;
      }
      .paragraph {
        .title-content {
          display: block;
          border-bottom: 1px solid $black;
          padding-bottom: 30px;
          margin-bottom: 30px;
          font-family: "Hoefler Text", serif;
          font-size: 14px;
          letter-spacing: 3.6px;
          text-transform: uppercase;
          line-height: 24px;
          text-align: center;
        }

        h2.title {
          text-align: center;
        }

        .assistance-available {
          text-align: center;
          font-size: $f-size-xxs;
        }
        .store-data li {
          font-size: $f-size-xxs;
          > a {
            padding-left: 0;
          }
        }
      }
    }
  }

  .cms-assistance .std .paragraph h2.title {
    text-align: center;
  }
}

.assistance-sections {
  .title-content {
    display: block;
    text-align: center;
    @include block-uppercase-subtitle;
    font-size: $f-size;
    display: block;
    border-bottom: 1px solid $black;
    text-transform: uppercase;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .title {
    font-family: $f-paragraph;
    font-size: $f-size;
    text-transform: uppercase;
    color: $black;
    padding: 20px 0;
    margin-bottom: 0;
  }
  h2.title {
    margin-bottom: 35px;
    padding: 0;
    text-align: center;
  }
  @include block-uppercase-subtitle();
  font-size: $f-size;
  @include paragraph-block-title;
}
// =============================================
// Lavora con noi
// =============================================
.cms-lavora-con-noi, .cms-work-with-us {
  .page-title {
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 35px;
    h1 {
      text-transform: uppercase;
    }
  }
  .std {
    font-family: $f-title;
    text-align: center;
    @include paragraph-block-title;
    #request {
      text-align: left;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 65%;
      margin: auto;
      margin-top: 50px;
      .left {
        display: flex;
        flex-direction: column;
        max-height: 400px;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 40px;
        border-bottom: 1px solid $black;
        .info {
          margin-bottom: 25px;
          text-transform: uppercase;
          width: 49%;
        }
        .inputfile {
          position: relative;
          max-width: 400px;
          > img {
            position: absolute;
            right: 8px;
            top: 8px;
          }
        }
      }
      .right {
        //flex-basis: 50%;
      }
      .check-terms {
        flex-basis: 50%;
        margin-top: 20px;
        display: flex;
        position: relative;
        input[type="checkbox"] {
          margin-top: 7px;
          margin-right: 10px;
        }
        .input-box {
          width: unset;
        }
        .validation-advice {
          bottom: -18px;
        }
      }
      > p.required {
        margin-top: 20px;
        flex-basis: 50%;
        font-family: $f-title;
        font-size: $f-size-xxs;
      }
      .button-container {
        flex-basis: 100%;
        text-align: center;
        margin-top: 40px;
        .btn-primary {
          max-width: 140px;
          float: unset;
          margin: auto;
          background-color: $black;
          color: $white;
          &:hover {
            background-color: $white;
            color: $black;
          }
        }
      }
      .select {
        max-width: 400px;
      }
      .input-box, .select {
        margin-bottom: 20px;
        width: 49%;
        //max-width: 400px;
      }
      #customer_cv {
        width: 100%;
      }
      .validation-advice {
        position: absolute;
        font-family: $f-title;
      }
    }
  }
}
@include bp(max-width, $bp-large) {
  .cms-lavora-con-noi, .cms-work-with-us {
    .page-title h1 {
      font-size: $f-size;
    }
    .std {
      font-size: $f-size-xxs;
    }
    .std #request {
      width: 100%;
      .left {
        max-height: unset;
        border-bottom: none;
        padding-bottom: 0;
        .input-box {
          width: 100%;
          input[type=text] {
            width: 100%;
          }
        }
        .info {
          text-transform: initial;
          text-align: center;
        }
        .info, .select, .inputfile {
          width: 100%;
          max-width: unset;
        }
      }
      .check-terms {
        flex-basis: 100%;
        order: 2;
      }
      .button-container {
        flex-basis: 100%;
        order: 3;
        button {
          background: $black;
          color: $white;
          margin-bottom: 80px;
          &:hover {
            background: $white;
            color: $black;
          }
        }
      }
      > p.required {
        flex-basis: 100%;
        margin-top: 0;
      }
      .validation-advice {
        font-size: $f-size-xxs;
      }
    }
  }
}
@include bp(max-width, $bp-small) {
  .cms-page-view {
    .mapouter {
      .gmap_canvas {
        #gmap_canvas {
          min-height: 300px;
          margin-bottom: 60px;
        }
      }
    }
  }
}