.product-costum.col2-img-text {
  .col2-set {
    display: flex;
    flex-direction: row;
    align-items: center;
    .col-1 {
      width: 55%;
      padding: 0;
      img {
        float: right;
        padding-right: 0;
      }
      .title, .subtitle, p {
        max-width: 585px;
        margin-left: auto;
        text-align: left;
        padding-right: 15%;
      }
    }
    .col-2 {
      width: 45%;
      position: unset;
      padding-left: 8%;
        .title, .subtitle, p {
          max-width: 450px;
        }
      &.img {
        padding-left: 0;
      }
    }
    .col-2, .col-1 {
      .title, .subtitle, p {
        position: unset;
      }
      .subtitle {
        margin-bottom: 50px;
      }
      .title {
        margin-bottom: 30px;
      }
      p {
        @include block-uppercase-subtitle;
      }
    }
  }
}
.product-costum.video-container-boxed {
  .text-left {
    margin-left: 55%;
    max-width: 25%;
    .title {
      margin-bottom: 30px;
    }
    .paragraph {
      p {
        @include block-uppercase-subtitle;
      }
      margin-bottom: 80px;
    }
  }
  video {
    max-width: 65%;
    float: right;
    margin-right: 12%;
  }
}

@include bp(max-width, $bp-xlarge + 1) {
  .product-costum.video-container-boxed {
    .text-left {
      max-width: 45%;
    }
    video {
      float: unset;
      max-width: 100%;
      margin-right: 0;
    }
  }
}