@mixin page-full-width() {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@mixin block-italic-title() {
  font-family: $f-paragraph;
  font-style: italic;
  color: $black;
  font-size: $f-size-xxxl;
}

@mixin block-uppercase-subtitle() {
  font-family: $f-title;
  font-size: $f-size-xxs;
  color: $black;
  letter-spacing: 1.82px;
  line-height: 12px;
  font-weight: 300;
}

@mixin paragraph-block-title() {
  line-height: 25px;
  letter-spacing: 2px;
  font-weight: 300;
}

@mixin action-secondary() {
  font-family: $f-title;
  text-transform: uppercase;
  font-size: $f-size-xxs;
  color: $black;
  border: 1px solid $black;
  padding: 5px 15px;
  text-decoration: underline;
  width: 140px;
  background-color: transparent;
}

@mixin action-primary() {
  font-family: $f-title;
  text-transform: uppercase;
  font-size: $f-size-xxs;
  color: $black;
  background-color: $white;
  border: 1px solid $black;
  padding: 5px 15px;
  &:hover {
    background-color: $black;
    color: $white;
  }
}